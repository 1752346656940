import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import * as _ from 'lodash'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import PerdaFisica from '../../models/perdaFisica'
import MaterialPerdaFisica from '../../models/materialPerdaFisica'
import fmt from '../../helpers/fmt'
import SalaVacina from '../../models/salaVacina'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { QuillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.snow.css';
import { nextTick } from 'vue'

interface PerdaFisicaForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect, QuillEditor},
    mixins: [PageMixin],
    store,
})
class PerdaFisicaForm extends Vue implements PerdaFisicaForm {
    fmt = fmt

    lo = _;

    name = 'PerdaFisicaForm';

    model = new PerdaFisica();

    bloquearForm:boolean;
    habilitaEnviarAnalise:boolean;
    habilitaEnviarMunicipio:boolean;
    habilitaEnviarRegional:boolean;
    habilitaEncerrar:boolean;
    habilitaRecusar:boolean;
    habilitaAprovar:boolean;
    habilitaDevolver:boolean;
    habilitaSalvar: boolean;
    habilitaEnviarGerencia: boolean;
    bloquearResposta: boolean;
    bloquearRespostaSuvisa: boolean;
    bloquearRespostaSuvisaEspecificarConduta: boolean;
    bloquearRespostaUploadSuvisa: boolean;
    bloquearRespostaGerencia: boolean;
    bloquearRespostaImunobiologicoConduta: boolean;
    bloquearCondutaComplementar: boolean;

    materiaisPerdaFisica = [new MaterialPerdaFisica()];
    alteracoesGeraisSelected = [];
    alteracoesOrganolepticasSelected = [];
    alteracoesFisicoQuimicaSelected = [];
    localConservacaoSelected = [];
    embalagensSelected = [];
    condutaAdotadaSelected = [];
    instrumentoMedicaoTempSelected = [];
    subMotivoSelected = [];
    salasDeVacinas = [];

    tiposUsuarios = {}
    tipoLocaisOcorrencia = [];
    tipoMotivos = [];
    tipoOcorrencias = [];
    tipoAlteracoesGerais = [];
    tipoAlteracoesOrganolepticas = [];
    tipoAlteracoesFisicoQuimica = [];
    tipoLocalConservacao = [];
    tipoEmbalagem = [];
    tipoCondutaAdotada = [];
    tipoDosesApresentadas = [];
    tipoInstrumentoMedicaoTemperatura = [];
    tipoOcorreuEm = [];
    tipoApresentacao = [];
    tipoImunobiologico = [];
    tipoFormaFarmaceutica = [];
    tipoSubMotivo = [];
    tipoMedidasAdotadas = [];
    diluentes = [];
    tipoLaboratorioProdutor = [];
    imunobiologicos = [];
    nLotes = [];
    nLotesDiluentes = [];
    tipoArquivos = [];
    anexos = [];
    anexosSuvisaGi = [];
    respostaImunobiologicoEnum = [];
       
    idPerdaFisica = null;
    idMunicipio = null;
    idRegional = null;
    idUnidade = null;
    idSalaVacina = null;
    cnesSalaVacina = null;
    municipios = [];
    regionais = [];
    unidades = [];
    tipoUsuario = {}
    idTipoUpload = null;
    descricaoArquivo = null;
    optionsDinamicoLotes = [];
    optionsDinamicoLotesDiluentes = [];
    descricaoArquivoSuvisaGi = null;
    respostaPorImunobiologico = [];
    
    messages = {
        model: this.model,
    };

    data() {
        return {
            editorOptions: {
                modules: {
                    toolbar: [
                        [{ 'header': [1, 2, false] }], // Cabeçalhos
                        ['bold', 'italic', 'underline', 'strike'], // Negrito, Itálico, Sublinhado e Tachado
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }], // Listas ordenadas e não ordenadas
                        [{ 'color': [] }, { 'background': [] }], // Cor do texto e fundo
                        [{ 'font': [] }], // Fontes
                        [{ 'size': ['small', false, 'large', 'huge'] }], // Tamanhos da fonte
                        ['clean'], // Limpar formatação
                        ['link', 'blockquote', 'code-block'], // Links, citação e bloco de código
                    ],
                },
            },
        };
    }

    change() {
        
    }

    computed(){

    }
    
    created() {
        this.idRegional = null;
        this.idMunicipio = null;
        this.idSalaVacina = null;
        this.cnesSalaVacina = null;
        this.anexos = [];
        this.anexosSuvisaGi = [];
        this.nLotes = [];
        this.salasDeVacinas = [];
        this.optionsDinamicoLotes = [];
        this.optionsDinamicoLotesDiluentes = [];
        this.respostaPorImunobiologico = [];
        this.tipoUsuario = localStorage.getItem('tipoUsuario')
        if(this.tipoUsuario == "2"){
            this.idRegional = localStorage.getItem('idRegional')
        }
        if(this.tipoUsuario == "3"){
            this.idMunicipio = localStorage.getItem('idMunicipio')
        }
        if(this.tipoUsuario == "4"){
            this.idUnidade = localStorage.getItem('idUnidade')
        }

        this.bloquearForm = false;
        this.habilitaEnviarAnalise = false;
        this.habilitaEnviarMunicipio = false;
        this.habilitaEnviarRegional = false;
        this.habilitaEncerrar = false;
        this.habilitaDevolver = false;
        this.habilitaRecusar = false;
        this.habilitaAprovar = false;
        this.habilitaEnviarGerencia = false;
        this.habilitaSalvar = true;
        this.bloquearResposta = true;
        this.bloquearRespostaSuvisa = true;
        this.bloquearRespostaSuvisaEspecificarConduta = true;
        this.bloquearRespostaGerencia = true;
        this.bloquearRespostaUploadSuvisa = true;
        this.bloquearRespostaImunobiologicoConduta = true;
        this.bloquearCondutaComplementar = false;

        this.idPerdaFisica = this.$route.params.id == "new" ? null : this.$route.params.id
        this.clearUploadFields();
        this.inicializarObjetos().then(res => {
            this.getPerdaFisica();
        });   
    }

    mounted() {
        nextTick(() => {
            this.observeFloatSaveButton();
          });
    }

    observeFloatSaveButton() {
        const observer = new MutationObserver(() => {
            const salvarTemporario = document.getElementById("salvar-temporario");
            const gridContainer = document.getElementById("buttons-container");
  
            if (salvarTemporario && gridContainer) {
    
                const intersectionObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
    
                    if (entry.isIntersecting) {
                        salvarTemporario.classList.add("hidden");
                    } else {
                        salvarTemporario.classList.remove("hidden");
                    }
                    });
                },
                {
                    root: null,
                    threshold: 0.1,
                    rootMargin: "0px",
                }
                );
    
                intersectionObserver.observe(gridContainer);
                observer.disconnect();
            }
        });
  
        observer.observe(document.body, { childList: true, subtree: true });
    }

    async getPerdaFisicaAnexosSuvisaGi(){
        await this.http().get(`${this.serverUrl}/perda-fisica/get-anexo-suvisa-gi/${this.idPerdaFisica}`)
            .then(res => {
                if(res.data.erro){
                    this.$swal.fire({title: res.data.msg+' - Redirecionando para a listagem', text: '', icon: 'error', timer: 2700});
                    setTimeout(() => {
                        this.$router.push('/perda-fisica');
                    } , 3000);
                    return;
                }
                this.anexosSuvisaGi = res.data.anexosPerdaFisicaSuvivaGi;
            });
    }

    async getPerdaFisica(){
        if(this.idPerdaFisica != null){
            await this.http().get(`${this.serverUrl}/perda-fisica/get-perda-fisica/${this.idPerdaFisica}`)
            .then(res => {
                if(res.data.erro){
                    this.$swal.fire({title: res.data.msg+' - Redirecionando para a listagem', text: '', icon: 'error', timer: 2700});
                    setTimeout(() => {
                        this.$router.push('/perda-fisica');
                    } , 3000);
                    return;
                }
                this.model = res.data.model;
                this.idRegional = this.model?.unidade?.municipio?.regional?.id;
                this.idMunicipio = this.model?.unidade?.chvmnc;
                let idUnidade = this.model.idUnidade;
                let observacaoGerencia = "";
                if (res.data.model.lastStatusPerdasFisicas?.idTipoStatus == 12 && res.data.usuarioLogado.idTipoUsuario == 5) {
                    observacaoGerencia = "Após análise das documentações solicitadas sobre o desvio de qualidade dos imunobiológicos, valido o parecer técnico emitido";
                }
                if (!res.data.model.observacaoGerencia) {
                    this.model.observacaoGerencia = observacaoGerencia?? res.data.model.observacaoGerencia;
                }
                this.filtrarUnidadePorMunicipio();
                this.calcularPeriodo();
                this.model.idUnidade = idUnidade;
                this.materiaisPerdaFisica = res.data.materiaisPerdaFisica;
                this.alteracoesGeraisSelected = res.data.alteracoesGeraisSelected;
                this.alteracoesOrganolepticasSelected = res.data.alteracoesOrganolepticasSelected;
                this.alteracoesFisicoQuimicaSelected = res.data.alteracoesFisicoQuimicaSelected;
                this.localConservacaoSelected = res.data.localConservacaoSelected;
                this.embalagensSelected = res.data.embalagensSelected;
                this.condutaAdotadaSelected = res.data.condutaAdotadaSelected;
                this.instrumentoMedicaoTempSelected = res.data.instrumentoMedicaoTempSelected;
                this.subMotivoSelected = res.data.subMotivoSelected;
                this.anexos = res.data.anexosPerdaFisica;
                this.anexosSuvisaGi = res.data.anexosPerdaFisicaSuvivaGi;
                this.respostaPorImunobiologico = res.data.newRespostaPorImunobiologico;
                
                if(this.materiaisPerdaFisica){
                    this.materiaisPerdaFisica.forEach((material, index) => {
                        if(material.idImunobiologico != null && material.idLaboratorioProdutor != null){
                            this.optionsDinamicoLotes[index] = this.nLotes.filter(lote => {
                                return lote.idImunobiologico == material.idImunobiologico && lote.idLaboratorioProdutor == material.idLaboratorioProdutor;
                            });
                        }
                        if(material.idDiluente != null && material.idLaboratorioDiluente != null){
                            this.optionsDinamicoLotesDiluentes[index] = this.nLotesDiluentes.filter(lote => {
                                return lote.idDiluente == material.idDiluente && lote.idLaboratorioProdutor == material.idLaboratorioDiluente;
                            });
                        }
                    });
                }

                if (this.model.idTipoOcorrencia != null && this.model.idTipoOcorrencia != 2) {
                    const idMedidaAdotada: number = 5;
                    this.tipoMedidasAdotadas = this.tipoMedidasAdotadas.filter(
                        (medidaAdotada) => medidaAdotada.id !== idMedidaAdotada
                    );
                }

                if (this.model.idTipoOcorrencia == 2) {
                    const idMedidaAdotada: number = 4;
                    this.tipoMedidasAdotadas = this.tipoMedidasAdotadas.filter(
                        (medidaAdotada) => medidaAdotada.id !== idMedidaAdotada
                    );
                }

                if(this.model.idSalaVacina){
                    let idSalaVacina = this.model.idSalaVacina;
                    this.buscarSalasDeVacina().then(res => {
                        this.model.idSalaVacina = idSalaVacina;
                        this.setarCnesSalaDeVacina();
                    });
                }

                /**
                 * Bloqueia o formulário e desabilita o botão de salvar se o último status for Em Análise ou Enviado para o Município ou Enviado para a Regional ou Reaberto ou Em Análise pela Gerência
                 */
                if([4, 7, 8, 9, 12, 13].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus)){
                    this.bloquearForm = true;
                    this.habilitaSalvar = false;
                    this.habilitaRecusar = false;
                    this.habilitaAprovar = false;
                }

                /**
                 * Habilita ou desabilita botão de enviar para análise se o ultimo status for do tipo 1- CRIADO ou 2- ALTERADO
                 * e estiver parametrizado para permitir enviar direto para o Estado
                 */
                if([1, 2].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && !this.model.unidade.enviarMunicipio && !this.model.unidade.enviarRegional){
                    this.habilitaEnviarAnalise = true;
                }

                /**
                 * Habilita ou desabilita botão de enviar para município se o ultimo status for do tipo 1- CRIADO ou 2- ALTERADO
                 * e estiver parametrizado para enviar pro municipio ou regional
                 */
                if([1, 2].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && (this.model.unidade.enviarMunicipio || this.model.unidade.enviarRegional)){
                    this.habilitaEnviarMunicipio = true;
                    this.habilitaEnviarAnalise = false;
                }

                /**
                 * Habilita ou desabilita botão de enviar para o Estado se o ultimo status for do tipo 7- ENVIADO PARA MUNICIPIO
                 * e se NÃO estiver parametrizado na Unidade para enviar para Regional
                 * e que o tipo de usuário logado seja MUNICIPIO
                 */
                if([7].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && this.tipoUsuario == 3 && !this.model.unidade.enviarRegional){
                    this.habilitaEnviarMunicipio = false;
                    this.habilitaEnviarAnalise = true;
                }

                /**
                 * Habilita ou desabilita botão de enviar para a Regional se o ultimo status for do tipo 7- ENVIADO PARA MUNICIPIO
                 * e estiver parametrizado na Unidade para enviar para Regional
                 * e que o tipo de usuário logado seja MUNICIPIO
                 */
                if([7].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && this.tipoUsuario == 3 && this.model.unidade.enviarRegional){
                    this.habilitaEnviarMunicipio = false;
                    this.habilitaEnviarAnalise = false;
                    this.habilitaEnviarRegional = true;
                }

                /**
                 * Habilita ou desabilita botão de enviar para o Estado se o ultimo status for do tipo 8- ENVIADO PARA REGIONAL
                 * e que o tipo de usuário logado seja REGIONAL
                 */
                 if([8].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && this.tipoUsuario == 2){
                    this.habilitaEnviarMunicipio = false;
                    this.habilitaEnviarAnalise = true;
                    this.habilitaEnviarRegional = false;
                }

                /**
                 * Habilita ou desabilita botão de encerrar / devolver / enviar para gerencia se o ultimo status for do tipo 4- EM ANÁLISE, 9- REABERTO ou 13- AGUARDANDO PARECER INCQS
                 * e que o tipo de usuário logado seja ADMINISTRADOR
                 */
                 if([4, 9, 13].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && this.tipoUsuario == 1){
                    this.habilitaEncerrar = true;
                    this.habilitaDevolver = true;
                    this.habilitaEnviarGerencia = true;
                    this.habilitaRecusar = false;
                    this.habilitaAprovar = false;
                }

                /**
                 * Habilita o botão de Recusar / Aprovar se o ultimo status for do tipo 12- EM ANÁLISE PELA GERÊNCIA
                 * e que o tipo de usuário logado seja GERÊNCIA DE IMUNIZAÇÃO
                 */
                 if([12].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && this.tipoUsuario == 5){
                    this.habilitaRecusar = true;
                    this.habilitaAprovar = true;
                    this.habilitaEnviarGerencia = false;
                }

                /**
                 * Habilita o botão de encerrar e desabilita o botão devolver se o ultimo status for do tipo 9- REABERTO
                 * e que o tipo de usuário logado seja ADMINISTRADOR
                 */
                 if([9].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus) && this.tipoUsuario == 1){
                    this.habilitaEncerrar = true;
                    this.habilitaDevolver = false;
                    this.habilitaRecusar = false;
                    this.habilitaAprovar = false;
                }

                /**
                 * Desabilita poder salvar se o status for encerrado
                 */
                if([5].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus)){
                    this.habilitaSalvar = false;
                }

                /**
                 * Bloqueando os campos e botões caso uma ocorrência estver com o status 3- EXCLUÍDO
                 */
                if ([3].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus)) {
                    this.bloquearForm = true;
                    this.habilitaDevolver = false;
                    this.habilitaEncerrar = false;
                    this.habilitaEnviarAnalise = false;
                    this.habilitaSalvar = false;
                }
                
            });
        }else{
            this.alteracoesGeraisSelected = [];
            this.alteracoesOrganolepticasSelected = [];
            this.alteracoesFisicoQuimicaSelected = [];
            this.localConservacaoSelected = [];
            this.embalagensSelected = [];
            this.condutaAdotadaSelected = [];
            this.instrumentoMedicaoTempSelected = [];
            this.subMotivoSelected = [];
            this.materiaisPerdaFisica = [new MaterialPerdaFisica()];
        }
    
        /**
         * Se o usuário for ADM e existir um idPerdaFisica e o status for : 4- EM ANÁLISE, 13- AGUARDANDO PARECER INCQS ou 9- REABERTO ai posso deixar habilitado para poder responder
         */
        if(this.tipoUsuario == 1 && this.model.id != null && [4, 13, 9].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus)){
            this.bloquearResposta = false;
            this.bloquearRespostaSuvisa = false;
            this.bloquearRespostaSuvisaEspecificarConduta = false;
            this.bloquearRespostaUploadSuvisa = false;
            this.bloquearRespostaGerencia = true;
        }

        /**
         * Se o usuário for ADM e existir uma ocorrencia e o status for 9- REABERTO ai será desabilitado para poder responder 
         */
        if(this.tipoUsuario == 1 && this.model.id != null && [9].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus)){
            this.bloquearResposta = false;
            this.bloquearRespostaSuvisa = false;
            this.bloquearRespostaSuvisaEspecificarConduta = true;
            this.bloquearRespostaUploadSuvisa = false
            this.bloquearRespostaGerencia = true;
            this.bloquearRespostaImunobiologicoConduta = false;
        }

        if([5].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus)){
            this.bloquearResposta = true;
            this.bloquearRespostaSuvisa = true;
            this.bloquearRespostaSuvisaEspecificarConduta = true;
            this.bloquearRespostaUploadSuvisa = true;
            this.bloquearForm = true;
            this.habilitaDevolver = false;
            this.habilitaEncerrar = false;
            this.habilitaEnviarAnalise = false;
            this.habilitaSalvar = false;
            this.bloquearRespostaGerencia = true;
            this.bloquearRespostaImunobiologicoConduta = false;
        }

        /**
         * Se o usuário for Gerência de Imunização e o status for 12- EM ANÁLISE PELA GERÊNCIA
         */
        if(this.tipoUsuario == 5 && this.model.id != null && [12].some(x => x == this.model.lastStatusPerdasFisicas?.idTipoStatus)){
            this.bloquearForm = true;
            this.bloquearResposta = true;
            this.bloquearRespostaSuvisa = true;
            this.bloquearRespostaUploadSuvisa = true;
            this.bloquearRespostaGerencia = false;
            this.bloquearRespostaImunobiologicoConduta = true;
        }

        // Verifica se material.id existe antes de acessar respostaPorImunobiologico
        this.bloquearCondutaComplementar = (
            [4,5,12,13].includes(this.model?.lastStatusPerdasFisicas?.tipoStatus?.id) || //4 - EM ANÁLISE / 5 - ENCERRADO / 13 - AGUARDANDO PARECER INCQS / 12 - EM ANÁLISE PELA GERÊNCIA
            this.bloquearRespostaImunobiologicoConduta
        );
    }

    async inicializarObjetos(){
        this.model = new PerdaFisica();
        await this.http().get(`${this.serverUrl}/perda-fisica/inicializar-objetos/`).then(resp => {
            if(this.idPerdaFisica == null){
                this.model = resp.data.modelo;
                Vue.set(this.model, 'idUnidade', this.idUnidade);
            }
            this.tipoLocaisOcorrencia = resp.data.tipoLocaisOcorrencia;
            this.tipoMotivos = resp.data.tipoMotivos;
            this.tipoOcorrencias = resp.data.tipoOcorrencias;
            this.tipoAlteracoesGerais = resp.data.tipoAlteracoesGerais;
            this.tipoAlteracoesOrganolepticas = resp.data.tipoAlteracoesOrganolepticas;
            this.tipoAlteracoesFisicoQuimica = resp.data.tipoAlteracoesFisicoQuimica;
            this.tipoLocalConservacao = resp.data.tipoLocalConservacao;
            this.tipoEmbalagem = resp.data.tipoEmbalagem;
            this.tipoCondutaAdotada = resp.data.tipoCondutaAdotada;
            this.tipoDosesApresentadas = resp.data.tipoDosesApresentadas;
            this.tipoInstrumentoMedicaoTemperatura = resp.data.tipoInstrumentoMedicaoTemperatura;
            this.tipoOcorreuEm = resp.data.tipoOcorreuEm;
            this.tipoApresentacao = resp.data.tipoApresentacao;
            this.tipoImunobiologico = resp.data.tipoImunobiologico;
            this.tipoFormaFarmaceutica = resp.data.tipoFormaFarmaceutica;
            this.tipoSubMotivo = resp.data.tipoSubMotivo;
            this.tipoMedidasAdotadas = resp.data.tipoMedidaAdotada;
            this.diluentes = resp.data.diluentes;
            this.tipoLaboratorioProdutor = resp.data.tipoLaboratorioProdutor;
            this.imunobiologicos = resp.data.imunobiologicos;
            this.tipoArquivos = resp.data.tipoArquivos;
            this.municipios = resp.data.municipios;
            this.regionais = resp.data.regionais;
            this.nLotes = resp.data.nLotes;
            this.nLotesDiluentes = resp.data.nLotesDiluentes;
            this.respostaImunobiologicoEnum = resp.data.respostaImunobiologicoEnum;
            this.model.salaVacina = new SalaVacina();
            if(this.tipoUsuario == "3"){
                this.filtrarUnidadePorMunicipio();
            }
        });
    }

    calcularPeriodo() {
        const inicio = this.model.inicioRegistroTemp ? new Date(this.model.inicioRegistroTemp) : null;
        const fim = this.model.fimRegistroTemp ? new Date(this.model.fimRegistroTemp) : null;
  
        if (!inicio || !fim) {
          this.model.periodo = ''; // Reseta o campo se alguma data estiver vazia
          return;
        }
  
        const diffMs = fim.getTime() - inicio.getTime();
        if (diffMs < 0) {
          this.model.periodo = 'Data final deve ser posterior à inicial.';
          return;
        }
  
        const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  
        this.model.periodo = `${diffDays} dias, ${diffHours} horas e ${diffMinutes} minutos`;
    }

    validarCamposPerdaFisica(){
        if(this.model.dataOcorrencia == null){
            Messages.error('É necessário informar a data de ocorrência.');
            $("#dataOcorrencia").trigger('focus');
            return;
        }

        if(this.model.idTipoLocalOcorrencia == null){
            Messages.error('É necessário informar o local de ocorrência.');
            this.scroll("#localOcorrencia");
            return;
        }

        if(this.model.idTipoLocalOcorrencia == 7 && (this.model.outroLocalOcorrencia == null || this.model.outroLocalOcorrencia == '')){
            Messages.error('É necessário informar o local de ocorrência.');
            this.scroll("#localOcorrencia");
            $("#outroLocalOcorrencia").trigger('focus');
            return;
        }

        if(this.model.idTipoLocalOcorrencia == 4 && (this.model.idSalaVacina == null || this.model.idSalaVacina == '')){
            Messages.error('É necessário informar a Sala de Vacina.');
            this.scroll("#idSalaVacina");
            $("#idSalaVacina").trigger('focus');
            return;
        }

        if(this.model.idUnidade == null){
            Messages.error('É necessário selecionar uma unidade.');
            this.scroll("#unidadePerdaFisicaSection");
            return;
        }
        
        if(this.model.idTipoOcorrencia == null){
            Messages.error('É necessário selecionar o tipo da ocorrência.');
            this.scroll("#tipoOcorrenciaSection");
            return;
        }

        if(this.alteracoesGeraisSelected.length <= 0){
            Messages.error('É necessário selecionar pelo menos uma "Alteração Geral".');
            this.scroll("#alteracaoGeralSection");
            return;
        }

        if (this.localConservacaoSelected.length <= 0) {
            Messages.error('É necessário selecionar pelo menos um "Local de Conservação".');
            this.scroll("#localConservacaoSection");
            return;
        }

        if(this.alteracoesGeraisSelected.find(x => x.id == 2) && (this.model.especificarParticulaEstranha == null || this.model.especificarParticulaEstranha == '')){
            Messages.error('É necessário especificar que tipo de partícula estranha.');
            this.scroll("#especificarParticulaEstranha");
            $("#especificarParticulaEstranha").trigger('focus');
            return;
        }

        if(this.alteracoesGeraisSelected.find(x => x.id == 3) && (this.model.formaFarmaceuticaDivergente == null || this.model.formaFarmaceuticaDivergente == '')){
            Messages.error('É necessário especificar a forma farmacêutica divergente.');
            this.scroll("#formaFarmaceuticaDivergente");
            $("#formaFarmaceuticaDivergente").trigger('focus');
            return;
        }

        if(this.alteracoesGeraisSelected.find(x => x.id == 4) && this.model.idTipoDoseApresentada == null){
            Messages.error('É necessário informar se a dose apresentada no frasco é maior ou menor do que o definido na bula .');
            this.scroll("#idTipoDoseApresentada");
            return;
        }

        if(this.alteracoesGeraisSelected.find(x => x.id == 4) && this.model.idTipoDoseApresentada == 1){
            if(this.model.qtdDoseMais == null || this.model.qtdDoseMais == ''){
                Messages.error('É necessário informar quantas doses vieram a mais.');
                this.scroll("#idTipoDoseApresentada");
                return;
            }
            if(this.model.dosesUsadas == null){
                Messages.error('É necessário informar se as doses foram usadas.');
                this.scroll("#idTipoDoseApresentada");
                return;
            }
        }

        if(this.alteracoesGeraisSelected.find(x => x.id == 4) && this.model.idTipoDoseApresentada == 2){
            if(this.model.qtdDoseMenos == null || this.model.qtdDoseMenos == ''){
                Messages.error('É necessário informar quantas doses vieram faltando.');
                this.scroll("#qtdDoseMenos");
                return;
            }
        }

        if(this.alteracoesGeraisSelected.find(x => x.id == 5 || x.id == 19) && (this.model.outraAlteracaoGeral == null || this.model.outraAlteracaoGeral == '')){
            Messages.error('É necessário informar qual outra alteração geral.');
            this.scroll("#outraAlteracaoGeral");
            $("#outraAlteracaoGeral").trigger('focus');
            return;
        }

        if(this.localConservacaoSelected.find(x => x.id == 4) && (this.model.outroLocalConservacao == null || this.model.outroLocalConservacao == '')){
            Messages.error('É necessário informar qual outro Local de Conservação.');
            this.scroll("#outroLocalConservacao");
            $("#outroLocalConservacao").trigger('focus');
            return;
        }

        if(this.model.idTipoMotivo == 7 && (this.model.motivoOutro == null || this.model.motivoOutro == '')){
            Messages.error('É necessário especificar a outra alteração.');
            this.scroll("#motivoOutro");
            $("#motivoOutro").trigger('focus');
            return;
        }

        /**
         * Fazer estas validações somente se o tipo da ocorrência da perda física for : Queixa Técnica
         */
        if(this.model.idTipoOcorrencia != null && this.model.idTipoOcorrencia == 2){
            if(this.alteracoesFisicoQuimicaSelected.find(x => x.id == 7) && (this.model.outraAlteracaoFisicoQuimica == null || this.model.outraAlteracaoFisicoQuimica == '')){
                Messages.error('É necessário informar qual outra Alteração Físico-Química.');
                this.scroll("#outraAlteracaoFisicoQuimica");
                $("#outraAlteracaoFisicoQuimica").trigger('focus');
                return;
            }
    
            if(this.embalagensSelected.find(x => x.id == 13) && (this.model.outraEmbalagem == null || this.model.outraEmbalagem == '')){
                Messages.error('É necessário informar qual outra embalagem.');
                this.scroll("#outraEmbalagem");
                $("#outraEmbalagem").trigger('focus');
                return;
            }
        }

        if(this.condutaAdotadaSelected.find(x => x.id == 6 || x.id == 8 || x.id == 14) && (this.model.outraCondutaAdotadaFrenteOcorrenciaAtual == null || this.model.outraCondutaAdotadaFrenteOcorrenciaAtual == '')){
            Messages.error('É necessário informar qual outra conduta adotada.');
            this.scroll("#outraCondutaAdotadaFrenteOcorrenciaAtual");
            $("#outraCondutaAdotadaFrenteOcorrenciaAtual").trigger('focus');
            return;
        }

        if(this.condutaAdotadaSelected.find(x => x.id == 4 || x.id == 12) && (this.model.identificarRedeFrioImunoEncaminhado == null || this.model.identificarRedeFrioImunoEncaminhado == '')){
            Messages.error('É necessário identificar a Rede de Frio ao qual o imunobiológico foi encaminhado.');
            this.scroll("#identificarRedeFrioImunoEncaminhado");
            $("#identificarRedeFrioImunoEncaminhado").trigger('focus');
            return;
        }

        if(this.model.informacoesComplementares == null || this.model.informacoesComplementares == ''){
            Messages.error('É necessário informar os detalhes da ocorrência.');
            $("#informacoesComplementares").trigger('focus');
            return false;
        }

        /**
         * Fazer estas validações somente se o tipo da ocorrência da perda física for : Excursão de Temperatura
         */
        if(this.model.idTipoOcorrencia != null && this.model.idTipoOcorrencia == 1){

            if(this.model.idTipoOcorreuEm == null){
                Messages.error('É necessário informar quando ocorreu.');
                this.scroll("#idTipoOcorreuEm");
                return false;
            }

            if(this.model.visualizadoCongelamento == null){
                Messages.error('É necessário informar se foi visualizado congelamento.');
                this.scroll("#visualizadoCongelamentoSection");
                return false;
            }

            if(this.instrumentoMedicaoTempSelected.find(x => x.id == 6) && (this.model.outroInstrumento == null || this.model.outroInstrumento == '')){
                Messages.error('É necessário informar qual outro instrumento de medição de temperatura.');
                this.scroll("#outroInstrumento");
                $("#outroInstrumento").trigger('focus');
                return;
            }

            if(this.model.tempMomentoOcorrencia == null || this.model.tempMomentoOcorrencia == ''){
                Messages.error('É necessário informar a temperatura registrada no momento da ocorrência.');
                $("#tempMomentoOcorrencia").trigger('focus');
                return false;
            }
            
            if(this.model.inicioRegistroTemp == null || this.model.inicioRegistroTemp == ''){
                Messages.error('É necessário informar o início da excursão de temperatura.');
                $("#inicioRegistroTemp").trigger('focus');
                return false;
            }
            if(this.model.fimRegistroTemp == null || this.model.fimRegistroTemp == ''){
                Messages.error('É necessário informar o fim da excursão de temperatura.');
                $("#fimRegistroTemp").trigger('focus');
                return false;
            }

            if (this.model.periodo == 'Data final deve ser posterior à inicial.') {
                Messages.error('Data final deve ser posterior à inicial.');
                $("#fimRegistroTemp").trigger('focus');
                return false;
            }

            if(this.model.idTipoMotivo == null){
                Messages.error('É necessário informar o motivo.');
                this.scroll("#motivoSection");
                return false;
            }

            if(this.model.idTipoMotivo == 3 && this.subMotivoSelected.length <= 0){
                Messages.error('É necessário informar qual procedimento inadequado.');
                this.scroll("#subMotivoSection");
                return false;
            }

            if(this.model.prodAlteracaoTempAntes == null){
                Messages.error('É necessário informar se houve alteração na temperatura anteriormente.');
                this.scroll("#alteracaoTempAntesSection");
                return false;
            }else{
                if(this.model.prodAlteracaoTempAntes === true && this.model.prodAlteracaoTempAntesTodos == null) {
                    Messages.error('É necessário informar se todos foram alterados.');
                    this.scroll("#obsPerguntaAnteriorSection");
                    return false;
                }else {
                    if(this.model.prodAlteracaoTempAntes && this.model.prodAlteracaoTempAntesTodos && (this.model.prodAlteracaoTempAntesData == null || this.model.prodAlteracaoTempAntesData == '')){
                        Messages.error('É necessário especificar a data.');
                        $("#prodAlteracaoTempAntesData").trigger('focus');
                        return false;
                    }
                    if(this.model.prodAlteracaoTempAntes && this.model.prodAlteracaoTempAntesTodos && (this.model.prodAlteracaoTempAntesMaxCelsius == null || this.model.prodAlteracaoTempAntesMaxCelsius == '')){
                        Messages.error('É necessário especificar a temperatura máxima.');
                        $("#prodAlteracaoTempAntesMaxCelsius").trigger('focus');
                        return false;
                    }
                    if(this.model.prodAlteracaoTempAntes && this.model.prodAlteracaoTempAntesTodos && (this.model.prodAlteracaoTempAntesMinCelsius == null || this.model.prodAlteracaoTempAntesMinCelsius == '')){
                        Messages.error('É necessário especificar a temperatura minima.');
                        $("#prodAlteracaoTempAntesMinCelsius").trigger('focus');
                        return false;
                    }
                }
                
                if(this.model.prodAlteracaoTempAntes && (this.model.algumaObsPerguntaAnterior == null)){
                    Messages.error('É necessário informar se há alguma observação referente à pergunta anterior.');
                    this.scroll("#obsPerguntaAnteriorSection");
                    return false;
                }
                if(this.model.prodAlteracaoTempAntes && this.model.algumaObsPerguntaAnterior && (this.model.obsPerguntaAnterior == null || this.model.obsPerguntaAnterior == '')){
                    Messages.error('É necessário descrever a observação referente à pergunta anterior.');
                    this.scroll("#obsPerguntaAnteriorSection");
                    return false;
                }
            }

            if(this.subMotivoSelected.find(x => x.id == 5) && (this.model.subMotivoOutro == null || this.model.subMotivoOutro == '')){
                Messages.error('É necessário informar qual outro procedimento inadequado.');
                this.scroll("#subMotivoOutro");
                $("#subMotivoOutro").trigger('focus');
                return;
            }

        }
        return true;
    }

    validarCamposImunobiologicos(obj: MaterialPerdaFisica): boolean {
        if (!obj) return false;
    
        // Lista básica de campos obrigatórios
        const camposObrigatorios: Array<any> = [
            obj.idImunobiologico,
            obj.idLaboratorioProdutor,
            obj.idNumeroLote,
            obj.dataRecebimento,
            obj.prazoValidade,
            obj.idTipoApresentacao,
            obj.qtdFrasco,
            obj.qtdDose,
        ];
    
        // Adicionar campos obrigatórios para tipo de imunobiológico 1
        if (obj.imunobiologico?.idTipoImunobiologico === 1) {
            camposObrigatorios.push(obj.idFormaFarmaceutica);
            if (this.model.idTipoOcorrencia === 1 || this.model.idTipoOcorrencia === 2) {
                camposObrigatorios.push(obj.tempArmAtualImunobiologico);
            }
        }
    
        // Adicionar campos obrigatórios para tipo de imunobiológico 2
        if (obj.imunobiologico?.idTipoImunobiologico === 2) {
            if (this.model.idTipoOcorrencia === 1 || this.model.idTipoOcorrencia === 2) {
                camposObrigatorios.push(obj.tempAtualVacina);
            }
            camposObrigatorios.push(
                obj.idDiluente,
                obj.idLaboratorioDiluente,
                obj.idNumeroLoteDiluente,
                obj.idTipoApresentacaoDiluente,
                obj.dataRecebimentoDiluente,
                obj.qtdFrascoDiluente,
                obj.qtdDoseDiluente,
                obj.tempAtualDiluente
            );
        }
    
        // Verificar se todos os campos obrigatórios estão preenchidos (não null e não undefined)
        return camposObrigatorios.every(campo => campo !== null && campo !== undefined && campo !== '');
    }    

    salvar(salvarEnviar: boolean, salvarTemporario?: boolean) {
        if(!this.validarCamposPerdaFisica()){
            return;
        }

        if(this.anexos.length <= 0 && !salvarTemporario){
            Messages.error('É necessário realizar o upload de no mínimo 1 arquivo.');
            return;
        }

        if ((!this.materiaisPerdaFisica || this.materiaisPerdaFisica.length <= 0) && !salvarTemporario) {
            Messages.error('É necessário informar pelo menos 1 imunobiológico.');
            return;
        }
        
        // Verifica se há valores inválidos no array
        if (this.materiaisPerdaFisica.some(item => item === undefined || item === null) && !salvarTemporario) {
            Messages.error('Existem itens inválidos na lista de imunobiológicos.');
            return;
        }

        var retornoValidacao = this.materiaisPerdaFisica.every(obj => this.validarCamposImunobiologicos(obj));
        if (!retornoValidacao && !salvarTemporario) {
            Messages.error('É necessário preencher todos os campos obrigatórios dos imunobiológicos.');
            return;
        }

        if(this.model.prodAlteracaoTempAntesTodos === false) {
            this.model.prodAlteracaoTempAntesData = null
            this.model.prodAlteracaoTempAntesMaxCelsius = null
            this.model.prodAlteracaoTempAntesMinCelsius = null
        } else {
            this.materiaisPerdaFisica.forEach(material => {
                material.excursaoAnterior = false
            })
        }

        this.materiaisPerdaFisica.forEach(material => {
            if(material.excursaoAnterior === false) {
                material.excursaoAnteriorData = null
                material.excursaoAnteriorTempMax = null
                material.excursaoAnteriorTempMin = null
            }
        })


        console.log(this.model)

        this.http().post(`${this.serverUrl}/perda-fisica/salvar`, {
            model: this.model,
            materiaisPerdaFisica : this.materiaisPerdaFisica,
            alteracoesGeraisSelected : this.alteracoesGeraisSelected,
            alteracoesOrganolepticasSelected : this.alteracoesOrganolepticasSelected,
            alteracoesFisicoQuimicaSelected : this.alteracoesFisicoQuimicaSelected,
            localConservacaoSelected : this.localConservacaoSelected,
            embalagensSelected : this.embalagensSelected,
            condutaAdotadaSelected : this.condutaAdotadaSelected,
            instrumentoMedicaoTempSelected : this.instrumentoMedicaoTempSelected,
            subMotivoSelected : this.subMotivoSelected,
            anexosPerdaFisica : this.anexos,
            salvarEEnviar : salvarEnviar
         })
        .then(resp => {
            if(resp.data.error){
                Messages.error(resp.data.error)
                this.$swal.fire('Erro!', '', 'error')
                return;
            }
            if (resp.data != null) {

                let msg: string;
                if (salvarEnviar) {
                    msg = "Dados Salvo e Enviado com Sucesso!";
                }else if(this.$route.params.id == "new"){
                    msg = "Dados Salvo com Sucesso!";
                }else{
                    msg = "Dados Editados com Sucesso!";
                }
                Messages.success(msg)
                this.$swal.fire('Sucesso!', '', 'success')
                this.$swal.fire({title: 'Sucesso!', text: '', icon: 'success', timer: 1250});
                setTimeout(() => {
                    if(salvarTemporario) {
                        if(this.$route.params.id == "new") {
                            this.$router.push({ path: `/perda-fisica/${resp.data.model.id}`,query: this.$route.query });
                        }
                    } else {
                        this.$router.push({ path: "/perda-fisica/", query: this.$route.query });
                    }
                } , 1000);
            } else {
                Messages.success('Os dados não foram salvos!')
                this.$swal.fire('Erro!', '', 'error')
            }
        }).catch(() => {});
    }

    scroll(id: any) {
        let element = document.querySelector(id);
        if(element){
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    limpar(){
        this.model = new PerdaFisica();
    }

    buscarTiposUsuario(){
        return this.http().get(`${this.serverUrl}/tipo-usuario/listar-tipo-usuario/`);
    }

    duplicarMaterial(){
        this.materiaisPerdaFisica.push(new MaterialPerdaFisica());
    }

    excluirMaterial(index){
        this.materiaisPerdaFisica.splice(index, 1);
    }

    filtrarAlteracoesGerais(idTipoOcorrencia){
        if(this.model.idTipoOcorrencia){
            let arrAlteracoes = this.tipoAlteracoesGerais.filter(alteracao => {
                return alteracao.idTipoOcorrencia == idTipoOcorrencia;
            });
            arrAlteracoes.sort((a, b) => a.ordem - b.ordem);

            return arrAlteracoes;
        }
    }

    filtrarCondutaAdotada(idTipoOcorrencia) {
        if (this.model.idTipoOcorrencia) {
            let arrayCondutas = this.tipoCondutaAdotada.filter(conduta => {
                return conduta.id_tipo_ocorrencia == idTipoOcorrencia;
            });
            arrayCondutas.sort((a, b) => a.ordem - b.ordem);

            return arrayCondutas;
        }
    }

    changeTipoOcorrencia(){
        this.alteracoesGeraisSelected = [];
    }

    changeLocalConservacao(){
        this.localConservacaoSelected = [];
    }

    changeRegional(){
        this.idMunicipio = null;
        this.model.idUnidade = null;
        this.unidades = [];
        this.buscarSalasDeVacina();
    }

    filtrarMunicipiosPorRegional(idRegional){
        if(idRegional == null){
            this.unidades = [];
        }
        let arrMunicipios = this.municipios.filter(municipio => {
            return municipio?.regional.id == idRegional;
        });
        return arrMunicipios;
    }

    async filtrarUnidadePorMunicipio(){
        this.unidades = [];
        Vue.set(this.model, 'idUnidade', null);
        this.buscarSalasDeVacina();
        if(this.idMunicipio != null){
            return await this.http().get(`${this.serverUrl}/unidade/listar-unidades/${this.idMunicipio}`).then(resp => {
                this.unidades = resp.data.unidades;
            });
        }
    }

    filtrarImunobiologicos(idTipoImunobiologico){
        let arrImunobiologicos = this.imunobiologicos.filter(imunobiologico => {
            return imunobiologico.idTipoImunobiologico == idTipoImunobiologico;
        });
        return arrImunobiologicos;
    }

    async buscarLotesDiluentes(idDiluente, idLaboratorioProdutor, optionsDinamicoLotesDiluentes, index, search, loading){
        if(idDiluente && idLaboratorioProdutor){
            loading(true);
            return await axios.post(`${this.serverUrl}/numero-lote-diluente/get-lista-lotes`, {
                search: search,
                idDiluente: idDiluente,
                idLaboratorioProdutor: idLaboratorioProdutor
            }).then(resp =>{
                loading(false);
                optionsDinamicoLotesDiluentes[index] = resp.data.nLotesDiluentes;    
                Vue.set(optionsDinamicoLotesDiluentes, index, resp.data.nLotesDiluentes);
            });
        }
    }

    async buscarLotesImunobiologicos(idImunobiologico, idLaboratorioProdutor, optionsDinamicoLotes, index, search, loading){
        if(idImunobiologico && idLaboratorioProdutor){
            loading(true);
            return await axios.post(`${this.serverUrl}/numero-lote/get-lista-lotes`, {
                search: search,
                idImunobiologico: idImunobiologico,
                idLaboratorioProdutor: idLaboratorioProdutor
            }).then(resp =>{
                loading(false);
                optionsDinamicoLotes[index] = resp.data.nLotes;
                Vue.set(optionsDinamicoLotes, index, resp.data.nLotes);
            });
        }
    }

    filtrarLotesImunobiologico(idImunobiologico, idLaboratorioProdutor, index){
        let lotes = [];
        if(idImunobiologico != null && idLaboratorioProdutor != null){
            lotes = this.nLotes.filter(lote => {
                return lote.idImunobiologico == idImunobiologico && lote.idLaboratorioProdutor == idLaboratorioProdutor;
            });
        }
        this.optionsDinamicoLotes[index] = lotes;
    }

    filtrarLotesDiluentes(idDiluente, idLaboratorioDiluente, index){
        let lotes = [];
        if(idDiluente != null && idLaboratorioDiluente != null){
            lotes = this.nLotesDiluentes.filter(lote => {
                return lote.idDiluente == idDiluente && lote.idLaboratorioProdutor == idLaboratorioDiluente;
            });
        }
        this.optionsDinamicoLotesDiluentes[index] = lotes;
    }

    filesChange(files){
        const formData = new FormData();
        if (files?.[0]) {
            let file = files[0];
        
            // Verifica se o arquivo é um PDF
            if (file.type !== "application/pdf") {
                Messages.error("Apenas arquivos PDF são permitidos.");
                this.$swal.fire("Tipo de arquivo inválido!", "Por favor, selecione um arquivo PDF.", "error");
                $('#upload_doc').val('');
                return;
            }
        
            // Verifica o tamanho do arquivo (máximo 10MB)
            if (file.size > 10485759) {
                Messages.error("Tamanho máximo de arquivo permitido é de 10MB.");
                this.$swal.fire("Arquivo muito grande!", "", "error");
                $('#upload_doc').val('');
                return;
            }
        }

        formData.append('file', files[0]);
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        this.http().post(`${this.serverUrl}/anexo-perda-fisica/upload-temp`, formData , { headers })
        .then(resp => {
            if(resp.data.erro){
                Messages.error(resp.data.msg);
                // this.$swal.fire('Erro!', '', 'error')
                return;
            }
            let anexo = {
                'tipoUpload' : 0,
                'descricao': this.descricaoArquivo,
                'nomeArquivo': resp.data.nomeArquivo,
                'arquivo': resp.data.arquivo,
                'excluido': false
            };
            this.anexos.push(anexo);
            this.clearUploadFields();
        }).catch((err) => {
            console.log(err);
        });
    }

    filesSuvisaGi(files) {
        const formData = new FormData();
        if (files?.[0]) {
            let file = files[0];
        
            // Verifica se o arquivo é um PDF
            if (file.type !== "application/pdf") {
                Messages.error("Apenas arquivos PDF são permitidos.");
                this.$swal.fire("Tipo de arquivo inválido!", "Por favor, selecione um arquivo PDF.", "error");
                $('#upload_doc_arq_suvisa_gi').val('');
                return;
            }
        
            // Verifica o tamanho do arquivo (máximo 10MB)
            if (file.size > 10485759) {
                Messages.error("Tamanho máximo de arquivo permitido é de 10MB.");
                this.$swal.fire("Arquivo muito grande!", "", "error");
                $('#upload_doc_arq_suvisa_gi').val('');
                return;
            }
        }

        formData.append('file', files[0]);
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        this.http().post(`${this.serverUrl}/anexo-perda-fisica/upload-temp`, formData , { headers })
        .then(resp => {
            if(resp.data.erro){
                Messages.error(resp.data.msg);
                return;
            }
            let anexo = {
                'tipoUpload' : 1,
                'descricao': this.descricaoArquivoSuvisaGi,
                'nomeArquivo': resp.data.nomeArquivo,
                'arquivo': resp.data.arquivo,
                'excluido': false
            };
            this.anexosSuvisaGi.push(anexo);

            this.http().post(`${this.serverUrl}/anexo-perda-fisica/upload-file-suvisa`, {
                model: this.model,
                anexosPerdaFisicaSuvivaGi: this.anexosSuvisaGi
            })
            .then(resp => {
                if (resp.data == null) {
                    this.$swal.fire({title: 'Arquivo Incluído com Sucesso!', text: '', icon: 'success', timer: 1250});
                    this.getPerdaFisicaAnexosSuvisaGi();
                } else {
                    Messages.success('O arquivo não foi excluído!');
                    this.$swal.fire('Erro!', '', 'error');
                }
            }).catch(() => {});
            this.clearUploadFields();
        }).catch((err) => {
            console.log(err);
        });
    }

    clearUploadFields(){
        this.idTipoUpload = null;
        this.descricaoArquivo = null;
        this.descricaoArquivoSuvisaGi = null;
        $('#upload_doc').val('');
        $('#upload_doc_arq_suvisa_gi').val('');
    }

    baixarArquivo(idAnexo){
        this.http().get(`${this.serverUrl}/anexo-perda-fisica/download-anexo/${idAnexo}`)
        .then(res => {
            if(res.data.erro){
                Messages.error(res.data.msg);
                return;
            }
            const byteCharacters = atob(res.data.arquivo);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url);
        });
    }

    excluirUpload(index){
        this.anexos.splice(index, 1);
    }

    excluirUploadSuvisaGI(anexo: { excluido: boolean }) {
        anexo.excluido = true;
        
        this.http().post(`${this.serverUrl}/anexo-perda-fisica/upload-file-suvisa`, {
            model: this.model,
            anexosPerdaFisicaSuvivaGi: this.anexosSuvisaGi
        })
        .then(resp => {
            if (resp.data == null) {
                this.$swal.fire({title: 'Arquivo Excluído com Sucesso!', text: '', icon: 'success', timer: 1250});
                this.getPerdaFisicaAnexosSuvisaGi();
            } else {
                Messages.success('O arquivo não foi excluído!');
                this.$swal.fire('Erro!', '', 'error');
            }
        }).catch(() => {});
    }

    enviarResposta(){
        //VERIFICAR TIPO DE OCORRENCIA PARA VALIDAR ITENS OBRIGATÓRIOS
        if ((this.model.idTipoOcorrencia == 1 || this.model.idTipoOcorrencia == 2) && (this.model.idTipoMedidaAdotada == '' || this.model.idTipoMedidaAdotada == null 
                                                    || this.model.respostaConduta == '' || this.model.respostaConduta == null)) { //QUEIXA TECNICA && EXCURSÃO DE TEMPERATURA
            this.$swal.fire({title: 'Erro!', text: 'Os Campos de Preenchimento Exclusivo da SUVISA/GI são obrigatórios', icon: 'error', timer: 3000});
            return;
        } else {
            const respostaPorImunobiologicoCopy = Object.fromEntries(
                Object.entries(this.respostaPorImunobiologico)
            );
            
            this.http().post(`${this.serverUrl}/perda-fisica/responder-perda-fisica`, {
                model: this.model,
                respostaPorImunobiologico: respostaPorImunobiologicoCopy,
                anexosSuvisaGi: this.anexosSuvisaGi,
                })
            .then(resp => {
                if(resp.data.error){
                    Messages.error(resp.data.error)
                    this.$swal.fire('Erro!', '', 'error')
                    return;
                }
                if (resp.data != null) {
                    Messages.success(resp.data.msg)
                    this.$swal.fire({title: 'Sucesso!', text: '', icon: 'success', timer: 1250});
                } else {
                    Messages.success('Os dados não foram salvos!')
                    this.$swal.fire('Erro!', '', 'error')
                }
            }).catch(() => {});
        }
    }

    buscarSalasDeVacina() {
        if (this.model.idUnidade != null && this.model.idTipoLocalOcorrencia == 4) {
            return this.http().get(`${this.serverUrl}/sala-vacina/buscar-salas-de-vacinas/${this.model.idUnidade}`)
                .then(res => {
                    if (res.data.salasDeVacinas.length == 1) {
                        this.model.idSalaVacina = res.data.salasDeVacinas[0].id;
                        this.setarCnesSalaDeVacina();
                    }
                })
                .catch(() => {});
        } else {
            this.salasDeVacinas = [];
            return Promise.resolve(); // 🔹 Retorna uma Promise vazia no else
        }
    }

    setarCnesSalaDeVacina(){
        if(this.model.idSalaVacina != null){
            Vue.set(this.model.salaVacina, 'cnesSalaVacina', this.salasDeVacinas?.find(x => x.id == this.model.idSalaVacina)?.cnesSalaVacina);
        }else{
            Vue.set(this.model.salaVacina, 'cnesSalaVacina', null);
        }
    }
    
    confirmarAcao(id, tipoAcao) {
        var mensagemFalha = "Ocorreu uma falha ao executar a ação ("+tipoAcao+")";
        if(tipoAcao == 'analise'){
            var mensagem = "Tem certeza que deseja enviar essa Ocorrência em Imunobiológico para Análise?";
            var mensagemSucesso = "Enviado para análise com sucesso";
        }else if(tipoAcao == 'encerrar'){
            var mensagem = "Tem certeza que deseja Encerrar essa Ocorrência em Imunobiológico?";
            var mensagemSucesso = "Encerrado com sucesso";
        }else if(tipoAcao == 'devolver'){
            var mensagem = "Tem certeza que deseja Devolver essa Ocorrência em Imunobiológico?";
            var mensagemSucesso = "Devolvido com sucesso";
        }else if(tipoAcao == 'municipio'){
            var mensagem = "Tem certeza que deseja enviar essa Ocorrência em Imunobiológico para o Município?";
            var mensagemSucesso = "Enviado para o município com sucesso";
        }else if(tipoAcao == 'regional'){
            var mensagem = "Tem certeza que deseja enviar essa Ocorrência em Imunobiológico para a Regional?";
            var mensagemSucesso = "Enviado para a regional com sucesso";
        }else if(tipoAcao == 'analiseGerencia'){
            var mensagem = "Tem certeza de que deseja enviar esta ocorrência para Aprovação da Gerência?";
            var mensagemSucesso = "Em análise pela Gerência";
        }else if(tipoAcao == 'aprovar') {
            var mensagem = "Tem certeza de que deseja aprovar esta ocorrência?";
            var mensagemSucesso = "Aprovado com Sucesso";
        }else if(tipoAcao == 'recusar') {
            var mensagem = "Tem certeza de que deseja recusar esta ocorrência?";
            var mensagemSucesso = "Recusado pela Gerência";
        }

        this.mensagemConfirmacao(
            mensagem, 
            'Faça sua escolha', 
            this.executarAcao.bind(null, mensagemSucesso, mensagemFalha, tipoAcao, id)
          )
    }
    
    executarAcao(mensagemSucesso, mensagemFalha, tipoAcao, id) {
        let textoPreservacao = tipoAcao == 'analise' ? 'Manter os imunos sob refrigeração entre +2.0°C e +8.0°C até parecer com as medidas a serem adotadas.' : '';
        let medidaAdotada = this.tipoMedidasAdotadas.find(x => x.id == this.model.idTipoMedidaAdotada);
        let andamento = {tipoMedidaAdotada: medidaAdotada, respostaConduta: this.model.respostaConduta, observacaoGerencia: this.model.observacaoGerencia, condutaComplementar: this.model.condutaComplementar};
        
        //VERIFICAR TIPO DE OCORRENCIA PARA VALIDAR ITENS OBRIGATÓRIOS
        if ((this.model.idTipoOcorrencia == 1 || this.model.idTipoOcorrencia == 2) && (andamento.tipoMedidaAdotada === undefined || andamento.tipoMedidaAdotada == null
                                                                                        || andamento.respostaConduta === "" || andamento.respostaConduta == null) && !this.habilitaEnviarAnalise) { //QUEIXA TECNICA && EXCURSÃO DE TEMPERATURA
                this.$swal.fire({title: 'Erro!', text: 'Os Campos de Preenchimento Exclusivo da SUVISA/GI são obrigatórios', icon: 'error', timer: 3000});
                return;
        } else {
            let respostasFiltradas = Object.fromEntries(
                Object.entries(this.respostaPorImunobiologico)
                    .filter(([key, item]) => item != null) // Filtra apenas itens que não sejam null ou undefined
            );
            

            this.http().post(`${this.serverUrl}/perda-fisica/alterar-status-perda-fisica`, {id: id, tipo: tipoAcao, andamento: andamento, respostaPorImunobiologico: respostasFiltradas})
                .then(resp => {
                    if (resp.data != null) {
                        Messages.success(mensagemSucesso)
                        this.$swal.fire({title: resp.data.msg, text: textoPreservacao, icon: 'success', confirmButtonText: `Ok`}).then(() => {
                            setTimeout(() => {
                                this.$router.push({ path: "/perda-fisica/", query: this.$route.query });
                            } , 500);
                          });
                        
                    } else {
                        Messages.success(mensagemFalha)
                    }
                })
                .catch(() => {})
        }
    }

    mensagemConfirmacao(titulo, corpoMensagem, callback){
        this.$swal({
          title: titulo,
          html: corpoMensagem,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Ok`,
          icon: 'warning'
        }).then((result) => {
          if (result.isConfirmed) {
            callback()
          } else if (result.isDismissed) {
            this.$swal.fire('Operação cancelada', '', 'info')
          }
        })
    }

    alertaGeladeira(){
        this.$swal.fire('Refrigerador de uso doméstico não é recomendado para o armazenamento de imunobiológicos.', 'Deverá ser substituído conforme RDC-Anvisa 197/2017.', 'info')
    }


}

export default PerdaFisicaForm