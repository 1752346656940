import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import PerdaFisica from '../../models/perdaFisica'
import MaterialPerdaFisica from '../../models/materialPerdaFisica'
import fmt from '../../helpers/fmt'
import { QuillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.snow.css';

interface HistoricoPerdaFisica extends PageMixin {}

@Component({
    components: {'v-select': VueSelect, QuillEditor},
    mixins: [PageMixin],
    store,
    props: ['dados', 'dadosObjetos']
})
class HistoricoPerdaFisica extends Vue implements HistoricoPerdaFisica {
    fmt = fmt

    name = 'HistoricoPerdaFisica';
    model = new PerdaFisica();

    data() {
        return {
            editorOptions: {
                modules: {
                    toolbar: [
                        [{ 'header': [1, 2, false] }], // Cabeçalhos
                        ['bold', 'italic', 'underline', 'strike'], // Negrito, Itálico, Sublinhado e Tachado
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }], // Listas ordenadas e não ordenadas
                        [{ 'color': [] }, { 'background': [] }], // Cor do texto e fundo
                        [{ 'font': [] }], // Fontes
                        [{ 'size': ['small', false, 'large', 'huge'] }], // Tamanhos da fonte
                        ['clean'], // Limpar formatação
                        ['link', 'blockquote', 'code-block'], // Links, citação e bloco de código
                    ],
                },
            },
        };
    }

    change() {

    }

    computed(){

    }
    
    created() {

    }

    mounted() {

    }

    baixarArquivo(idAnexo){
        this.http().get(`${this.serverUrl}/anexo-perda-fisica/download-anexo/${idAnexo}`)
        .then(res => {
            if(res.data.erro){
                Messages.error(res.data.msg);
                return;
            }
            const byteCharacters = atob(res.data.arquivo);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url);
        });
    }

    filtrarCondutaAdotada(idTipoOcorrencia, tipoCondutaAdotadas) {
        if (idTipoOcorrencia) {
            let arrayCondutas = tipoCondutaAdotadas.filter(conduta => {
                return conduta.id_tipo_ocorrencia == idTipoOcorrencia;
            });
            arrayCondutas.sort((a, b) => a.ordem - b.ordem);

            return arrayCondutas;
        }
    }

}

export default HistoricoPerdaFisica