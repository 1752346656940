















export default {

  name: "PerdaFisica",

  data() {
    return {
      
    };
  },
  
  methods: {
  }
};
